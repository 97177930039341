
<template>
  <!-- 办事指南 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :toolActions="toolActions"
      :dataSourceApi="api.guideList"
      @add="openDialog"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      @bulkDeletion="deleteSecurityPersonnel"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <a-switch
        v-model="record.flag"
        slot="flag"
        slot-scope="{ record }"
        @change="flagChange(record)"
      />
    </ax-table>
    <!-- 新增弹窗 -->
    <lssueDialog ref="lssueDialog" @refresh="refresh"></lssueDialog>
    <!-- 详情弹窗 -->
    <issueDetails ref="issueDetails"></issueDetails>
  </div>
</template>
 
 <script>
import lssueDialog from "./lssueDialog.vue";
import issueDetails from "./issueDetails.vue";
import api from "../api";
import { initGridFormData } from "@/common/tools";
const searchForm = [
  {
    label: "事项名称",
    type: "",
    model: "itemName",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属分类",
    type: "select",
    model: "itemType",
    options: {
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];

export default {
  components: { lssueDialog, issueDetails },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "事项名称",
            dataIndex: "itemName",
            ellipsis: true,
            key: 2,
            width: 150,
          },
          {
            title: "分类",
            dataIndex: "itemTypeName",
            ellipsis: true,
            key: 3,
            width: 120,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 100,
            key: 5,
          },
          {
            title: "操作人",
            dataIndex: "updateBy",
            ellipsis: true,
            key: 6,
          },
          {
            title: "更新时间",
            dataIndex: "updateTime",
            ellipsis: true,
            key: 7,
          },
          {
            title: "是否启用",
            dataIndex: "flag",
            ellipsis: false,
            key: 8,
          },
        ],
        false,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556bff",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),

      // 操作按钮
      toolActions: [
        { name: "add", text: "新增" },
        { name: "bulkDeletion", text: "批量删除", type: "#f95a5a" },
      ],
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },

  methods: {
    openDialog() {
      this.$refs.lssueDialog.openDialog({titles:'新增办事指南'});
    },
    // 列表分类查询
    async getListall() {
      try {
        const res = await api.guideListall();
        this.$nextTick(() => {
          const options = res.data.map((res) => {
            return { label: res.typeName, value: res.id };
          });
          this.$refs.logTable.$children[0].$refs.searchForm.setFormItemProp(
            "itemType",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "请选择所属分类",
              },
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 列表网格查询
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.logTable.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "请选择所属网格",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
        this.$refs.lssueDialog.openDialog({record:record,titles:'编辑办事指南'});
          break;
      }
    },
    // 表格刷新
    refresh() {
      this.$refs.logTable.getDataSource();
    },
    // 启用状态修改
    // 启用状态
    async flagChange(record) {
      try {
        await api.editGuide({
          id: record.id,
          flag: record.flag,
        });
        this.$refs.logTable.getDataSource();
      } catch (error) {
        console.log(error);
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      try {
        const res = await api.delGuide({ id: record.id });
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.$refs.logTable.getDataSource();
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.deletesGuide({
              ids: that.selectedRowKeys.join(","),
            });
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.logTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
  },

  created() {},

  mounted() {
    this.$nextTick(() => {
      this.getListall();
      this.getgridTree();
    });
  },
};
</script>
 <style lang='less' scoped>
 /deep/.ant-switch {
  background-color: #bfbfbf;
}
.ant-switch-checked {
  background-color: #409eff;
}
</style>